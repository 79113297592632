import React from "react";

export default function Header() {
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-widget="pushmenu"
                        href={() => false}
                        role="button"
                    >
                        <i className="fas fa-bars" />
                    </a>
                </li>
            </ul>
        </nav>
    );
}
