export function elementsLocked(data, tracking) {
    return [
        {
            key: "keyTouch",
            label: "Bloqueo de tactil",
            value: data.touch,
            id: "touch",
            type: "checkbox",
        },
        {
            key: "keyScreen",
            label: "Bloqueo de pantalla",
            value: data.screen,
            id: "screen",
            type: "checkbox",
        },
        {
            key: "keyBackground",
            label: "Cambiar fondo de pantalla",
            value: data.background,
            id: "background",
            type: "checkbox",
        },
        {
            key: "keyAlarm",
            label: "Activar alarma",
            value: data.alarm,
            id: "alarm",
            type: "checkbox",
        },
        {
            key: "keyTracking",
            label: "Activar Tracking",
            value: tracking.statusTracking,
            id: "statusTracking",
            type: "checkbox",
        },
        {
            key: "keyTrackingTrack",
            display: tracking.statusTracking,
            label: "Toma de datos",
            small: "(en minutos)",
            value: tracking.tracking,
            id: "tracking",
            type: "number",
        },
        {
            key: "keyReportTracking",
            display: tracking.statusTracking,
            label: "Envio de datos",
            small: "(en minutos)",
            value: tracking.report,
            id: "report",
            type: "number",
        },
        {
            key: "keyPhotoStatus",
            display: tracking.statusTracking,
            label: "Generar imagenes",
            value: data.photo.status,
            id: "status",
            type: "checkbox",
        },
        {
            key: "keyPhotoTime",
            display: tracking.statusTracking === true ? (data.photo.status === true ? true : false) : false,
            label: "Intervalo de imagenes",
            small: "(en minutos)",
            value: data.photo.time,
            id: "time",
            type: "number",
        },
        {
            key: "keyPhotoQuality",
            display: tracking.statusTracking === true ? (data.photo.status === true ? true : false) : false,
            label: "Resolucion de imagenes: ",
            value: data.photo.quality,
            id: "resolution",
            type: "options",
            default: "Seleccione la calidad de imagen",
            options: [
                {
                    value: "high",
                    title: "Alto",
                },
                {
                    value: "mid",
                    title: "Medio",
                },
                {
                    value: "low",
                    title: "Bajo",
                },
            ],
        },
        {
            key: "keyStatusMessage",
            label: "Enviar mensaje",
            value: data.statusMessage,
            id: "statusMessage",
            type: "checkbox",
        },
        {
            key: "keyRecursive",
            display: data.statusMessage,
            label: "Recursivo",
            value: data.recursive,
            id: "recursive",
            type: "checkbox",
        },

        {
            key: "keyMessageTitle",
            label: "Titulo de mensaje",
            display: data.statusMessage,
            value: data.messageTitle,
            id: "messageTitle",
            type: "text",
        },
        {
            key: "keyMessageBody",
            label: "Cuerpo de mensaje",
            display: data.statusMessage,
            value: data.messageBody,
            id: "messageBody",
            type: "area",
        },
    ];
}
